import React from "react"
import BackgroundImage2 from "../components/BackgroundImage"
import Syllabics from "./Syllabics"

// If you need to use the z-index for some reason (current use case was the overlapping homepage boxes).
// You have to pass zIndex={value} down from the hero, which will also pass it down to the BackgroundImage component

const Hero = ({
  englishTitle = "Needs Title",
  syllabics = "",
  heroImage = null,
  centered = false,
  zIndex = null,
  children,
  bgClassName,
}) => {
  return (
    <div className="flex md:min-h-32rem justify-center overflow-hidden ">
      <BackgroundImage2
        zIndex={zIndex}
        heroImage={heroImage}
        className={bgClassName}
      >
        <div
          className={`${
            centered ? "mx-auto" : "mx-auto md:ml-36 lg:ml-48 "
          } my-20 `}
        >
          <div className="flex flex-col  sm:max-w-md">
            {syllabics && (
              <h1 className="font-heading text-4xl text-center m-0 mb-3 sm:text-3xl p-4  bg-white bg-opacity-75 text-primary-light">
                <Syllabics>{syllabics}</Syllabics>
              </h1>
            )}
            {englishTitle && (
              <h1 className="font-heading text-5xl text-center m-0 tracking-tight p-4  bg-white bg-opacity-75 text-black">
                {englishTitle}
              </h1>
            )}
          </div>
          {children}
        </div>
      </BackgroundImage2>
    </div>
  )
}

export default Hero
