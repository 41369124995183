import React from "react"

export default ({ className = "", children, id = "", noLines = false }) => {
  return (
    <div className="flex justify-center items-center mt-4" id={id}>
      {!noLines && (
        <hr className="ml-4 mr-4 md:ml-32 md:mr-4 flex-grow border-t-4" />
      )}
      <h1 className={`font-heading text-4xl text-center ${className}`}>
        {children}
      </h1>
      {!noLines && (
        <hr className="ml-4 mr-4 md:ml-4 md:mr-32 flex-grow border-t-4" />
      )}
    </div>
  )
}
