import React from 'react';

import SectionTitle from "../components/SectionTitle"
import { FaChevronRight } from 'react-icons/fa';




export default ({text = "", classNames = "", color = 'blue', children }) => {


    return (
      <div className='my-12'>
        <SectionTitle>{children}</SectionTitle>
        <form className='flex justify-center mx-auto p-4'>
          <input placeholder='Email' type='email' className='p-4 bg-gray-light placeholder-black' required></input>
          <button type='submit' className='p-4 w-14 text-white font-black bg-gray-dark'>
            <FaChevronRight className='text-2xl' />
          </button>
        </form>
      </div>
      )
}
