import React from "react"

const PageSection = ({ innerContent, outerContent, className }) => {
  return (
    <div className={`grid justify-center ${className}`}>
      {outerContent}
      <section className="grid gap-y-10 px-5 md:px-10 my-10 justify-self-stretch w-full ">
        {innerContent}
      </section>
    </div>
  )
}

export default PageSection
