import React from "react"
import { graphql, Link } from "gatsby"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import NewsletterSignUp from "../components/NewsletterSignup"
import BlockContentPrimary from "../components/blockContentPrimary"
import PageSection from "../components/global/PageSection"

const About = ({ data }) => {
  const {
    englishTitle = "Error: No Title Provided",
    syllabicsTitle,
    aboutUsHeadline,
    aboutUsSummary = "Error: No information provided",
    heroImage,

    _rawMainContent: rawMainContent,
  } = data?.sanityAboutUs || {}

  const hero = heroImage ? heroImage.asset : data.heroDefault.childImageSharp

  return (
    <Layout>
      <SEO title="About Us" />
      <Hero
        zIndex={-1}
        englishTitle={englishTitle}
        syllabics={syllabicsTitle}
        heroImage={hero}
        bgClassName="bg-top"
      />
      {aboutUsSummary && (
        <section className="flex flex-col justify-center md:-mt-12 mt-0  bg-gray-dark max-w-4xl mx-auto px-5 sm:px-16">
          {aboutUsHeadline && (
            <h3 className="font-heading text-center mt-4 mb-4 text-4xl">
              {aboutUsHeadline}
            </h3>
          )}
          <p className="text-justify text-xl w-full  py-4  leading-snug">
            {aboutUsSummary}
          </p>
        </section>
      )}
      <PageSection
        innerContent={
          <section className=" text-primary-dark max-w-6xl ">
            <div className="text-primary-dark text-md sm:text-justify ">
              <BlockContentPrimary blockData={rawMainContent} />
            </div>
          </section>
        }
        className=" justify-center "
      ></PageSection>
      {/* <NewsletterSignUp>Get In The Loop</NewsletterSignUp> */}
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutUs {
    sanityAboutUs {
      englishTitle
      syllabicsTitle
      aboutUsHeadline
      aboutUsSummary

      _id
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawMainContent(resolveReferences: { maxDepth: 10 })
    }

    heroDefault: file(relativePath: { eq: "forest-lake.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1000, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
